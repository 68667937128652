<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppIcon from "@/Components/Shared/icon/AppIcon.vue";
import AppBadge from "@/Components/Shared/badge/AppBadge.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import AppImage from "@/Components/Shared/image/AppImage.vue";
import AppUserAvatar from "@/Components/Shared/user/AppUserAvatar.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "@/Components/Shared/tooltip";
import AppBookmarkButton from "@/Components/App/bookmarks/AppBookmarkButton.vue";
import AppDropdown from "@/Components/Shared/dropdown/AppDropdown.vue";
import AppMenu from "@/Components/Shared/menu/AppMenu.vue";
import AppMenuItem from "@/Components/Shared/menu/AppMenuItem.vue";
import useEmitter from "@/Composables/useEmitter";
import {isCurrentUserById, isGuest, isLoggedIn, isVerified, mustBeLogin, mustBeVerified} from "@/Utils/Helpers";
import TheFlagForm from "@/Components/App/forms/TheFlagForm.vue";
import {computed} from "vue";

type Props = {
    deck: object;
    hideAuthor?: boolean;
};
const { deck, hideAuthor = false } = defineProps<Props>();

// classes
const classes: AntlerClasses<Props> = {
    base: "flex flex-col border border-slate-200 rounded h-full text-center",
};
const { aClass } = installAntlerComponent("card", {}, classes);

const isOwner = computed(() => {
    return isLoggedIn && deck.author
        ? isCurrentUserById(deck.author.id)
        : false;
});

const {emitter} = useEmitter();

const flag = () => {
    if (isGuest.value) {
        mustBeLogin();
        return;
    }

    if (!isVerified.value) {
        mustBeVerified();
        return;
    }

    emitter.emit("modal", {
        component: TheFlagForm,
        title: "Deck rapporteren",
        props: {
            itemId: deck.id,
            path: deck._path,
            type: "deck",
        },
    });
};
</script>

<template>
    <div :class="aClass()">
        <AppLink :href="deck.path" class="flex flex-col items-center px-3 py-4 gap-2 h-full bg-white hover:no-underline hover:bg-slate-50">
            <div v-if="deck.card">
                <AppImage
                    :alt="deck.name"
                    class="max-h-[100px] max-w-[80px]"
                    :src="deck.card.image"
                    placeholder
                    variant="card"
                />
            </div>

            <div class="flex items-center gap-2 mt-auto">
                <AppTitle
                    as="h3"
                    size="xsmall"
                    class="mt-auto line-clamp-2"
                >
                    {{ deck.name }}
                </AppTitle>

                <AppIcon
                    v-if="!deck.is_public"
                    name="lock"
                    color="gray"
                    size="small"
                />
            </div>

            <AppUserAvatar
                v-if="deck.author && !hideAuthor"
                :user="deck.author"
                show-name
                avatar-size="xsmall"
                class="mb-2"
            />
        </AppLink>

        <div class="flex gap-3 items-center border-t grow p-2 border-slate-100">
            <AppLink
                v-if="deck.is_public"
                icon="comment"
                icon-size="small"
                variant="buttonXxSmallGray"
                :href="`${deck.path}#comments`"
            >
                {{ deck.good_comments_count || deck.comments_count }}
            </AppLink>

            <AppBadge
                v-if="deck.game"
                :color="deck.game.slug"
            >
                {{ deck.game.slug }}
            </AppBadge>

            <TooltipProvider v-if="deck.has_play">
                <Tooltip>
                    <TooltipTrigger as-child>
                        <AppIcon name="play" size="small" />
                    </TooltipTrigger>
                    <TooltipContent>
                        Dit deck heeft een speelwijze
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            <AppDropdown
                :variant="['right', 'relative']"
                :caret="false"
                size="small"
                class="ml-auto"
            >
                <template #toggle>
                    <AppButton icon="dots" variant="ghost" size="small" icon-color="gray" />
                </template>

                <AppMenu>
                    <AppMenuItem v-if="isOwner">
                        <AppLink
                            :href="`/decks/edit/${deck.id}`"
                            variant="buttonGhost"
                            class="align-left w-full"
                            icon="edit"
                            icon-size="xsmall"
                        >
                            Wijzig
                        </AppLink>
                    </AppMenuItem>
                    <template v-else>
                        <AppMenuItem>
                            <AppButton
                                variant="ghost"
                                align="left"
                                icon="flag"
                                icon-size="xsmall"
                                :size="['full', 'small']"
                                @click="flag"
                            >
                                Rapporteren
                            </AppButton>
                        </AppMenuItem>
                        <AppMenuItem>
                            <AppBookmarkButton
                                :path="deck._path"
                                :bookmarked="deck.bookmarked"
                                :button-size="['full', 'small']"
                            />
                        </AppMenuItem>
                    </template>
                </AppMenu>
            </AppDropdown>
        </div>
    </div>
</template>
